@media only screen and (min-width: 768px) {
    .carousel-item {
      height: 50vh;
    }
  }
  @media only screen and (min-width: 992px) {
    .carousel-item {
      height: 50vh;
    }
  }
  @media only screen and (min-width: 1200px) {
    .carousel-item {
      height: 50vh;
    }
  }
  
  .carousel-indicators li{
    background-color: #999;
  }
  .carousel-indicators .active{
    background-color: #444;
  }